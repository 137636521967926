import React from 'react';
import FullError from './FullError';

const WithError = ({ error, children }) => (
  <>
    {error && <FullError error={error} />}
    {!error && children}
  </>
);

export default WithError;
