import React from 'react';

import styled from '@emotion/styled';
import { border, breakpoints, color, flex } from '../../../../utils/style';
import config from '../../../shared/Prediction/Matches/Match/config';

const Wrap = styled('div')`
  width: 30px;
  height: 30px;
  border-radius: ${border.borderRadius4};
  background-color: ${color.lightningYellow};
  margin-left: auto;

  ${flex};

  & > span {
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 15px;
    text-transform: uppercase;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-left: 17px;

    & > span {
      color: ${color.bunting};
    }
  }
`;

const WinMode = ({ predictNum }) => (
  <Wrap>
    <span>{config[predictNum]}</span>
  </Wrap>
);

export default WinMode;
