import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import home from '../../img/home.svg';
import { breakpoints, flex, color } from '../../utils/style';
import { validateLink } from '../../utils/system';

const BreadcrumbsWrap = styled('div')`
  width: max-content;
  height: 20px;
  user-select: none;

  ${flex};

  & > div {
    height: 100%;

    &:last-child {
      opacity: 0.7;
    }

    & span,
    & a {
      height: 100%;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${color.white};
      opacity: 1;
    }

    & > a {
      cursor: pointer;

      & > img {
        position: relative;
        margin-right: 0;
      }
    }

    & .sep {
      margin: 0 5px;
      width: 3px;
      padding: 0;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    height: max-content;
    margin-bottom: 30px;

    justify-content: flex-start;
    flex-wrap: wrap;

    & > div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 288px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    & > div {
      max-width: 80%;
    }
  }
`;

const Breadcrumbs = (data) => {
  const separator = ' / ';
  const { breadcrumbs } = data;
  const { page_title } = data;
  return (
    <BreadcrumbsWrap className="breadcrumbs">
      {breadcrumbs && breadcrumbs.length > 0
        ? breadcrumbs?.map((breadcrumb) => (
          <div key={breadcrumb.slug}>
            <Link to={`${validateLink(breadcrumb.slug)}`}>
              {breadcrumb.title === 'Home' ? <img src={home} alt="home" /> : breadcrumb.title}
            </Link>
            <span className="sep">{separator}</span>
          </div>
        ))
        : ''}
      <div>
        <span>{page_title}</span>
      </div>
    </BreadcrumbsWrap>
  );
};

export default Breadcrumbs;
