import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, flex, color } from '../../../../utils/style';
import { getMonthsArr } from '../../../../utils/system';

const Wrap = styled.div`
  width: 50px;
  height: 100%;
  
  ${flex};
  justify-content: flex-start;
  
  & > span {
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.white};
  }
  
  & .time {
    width: 100%;
    font-size: 15px;
    
    ${flex};
  }
  
  & .date {
    width: 100%;
    font-size: 13px;
    opacity: 0.6;
    text-transform: uppercase;
  }
  
  & .desktop {
    display: flex;
  }
  
  & > div {
    width: max-content;
    height: 100%;
    
    ${flex};
    flex-direction: column;
    align-items: flex-start;
  }
  
  @media (max-width: ${breakpoints.md}) {
    justify-content: flex-start;
    padding-right: 8px;
  
    & .time {
      justify-content: flex-start;
      font-size: 14px;
    }
    
    & .date {
      font-size: 12px;
    }
  }
`;

const TableDate = ({ matchDate, time }) => {
  const date = new Date(matchDate);

  return (
    <Wrap>
      <div className="desktop">
        <span className="time">{time}</span>
        <span className="date">{date.getDate()}&nbsp;{getMonthsArr(true)[date.getMonth() + 1]}</span>
      </div>
    </Wrap>
  );
};

export default TableDate;
