import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, flex, color } from '../../../../utils/style';

const Wrap = styled.div`
  flex-grow: 1;
  margin-left: 30px;
  
  ${flex};
  
  .team {
    width: 150px;
    height: 100%;
    
    ${flex};
    justify-content: flex-start;
    
    & > img {
      width: 20px;
      margin-right: 17px;
    }
    
    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 15px;
      font-weight: 500;
      color: ${color.white};
    }
  }
  
  .divider {
    margin: 0 50px;
    
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${color.white}
  }
  }
  
  @media (max-width: ${breakpoints.md}) {
    position: relative;
    flex-direction: column;
    align-content: space-between;
    padding: 12px 0;
    margin-left: 0;
    
    .team {
      width: 100px;
      padding-left: 0;
    
      & > img {
        width: 20px;
        margin-right: 6px;
      }
    }
  
    .divider {
      display: none;
    }
  }
`;

const MobileDivider = styled('div')`
  display: none;

  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 10px 0;
  margin-left: auto;

  & > span {
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: ${color.white};
  }

  @media (max-width: ${breakpoints.md}) {
    display: flex;
  }
`;

const teamBox = (name, logo) => (
  <div className="team">
    <img alt={name} src={logo} />
    <span>{name}</span>
  </div>
);

const TableTeams = ({ home = {}, away = {}, completed = true, homePredict, awayPredict }) => {
  const { name: homeName, logo: homeLogo } = home;
  const { name: awayName, logo: awayLogo } = away;

  return (
    <>
      <Wrap className={completed ? 'completed' : ''}>
        {teamBox(homeName, homeLogo)}
        <div className="divider">
          {completed ? (
            <span>
              {homePredict}&nbsp;:&nbsp;{awayPredict}
            </span>
          ) : (
            <span>?&nbsp;:&nbsp;?</span>
          )}
        </div>
        {teamBox(awayName, awayLogo)}
      </Wrap>
      <MobileDivider className="divider">
        <span>{completed ? homePredict : '?'}</span>
        <span>{completed ? awayPredict : '?'}</span>
      </MobileDivider>
    </>
  );
};

export default TableTeams;
