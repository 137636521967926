import React, { useContext } from 'react';

import styled from '@emotion/styled';
import { border, breakpoints, color } from '../../../utils/style';
import dictionary from '../../../utils/dictionary';

import { StoreContext } from '../../../store';
import TopAlert from './TopAlert';
import Matches from './Matches';
import GameHistory from './GameHistory';

const Wrap = styled('div')`
  width: 100%;
  min-height: 100px;
  border-radius: 0 0 ${border.borderRadius4} ${border.borderRadius4};
  background-color: ${color.darkPurple};
  padding: 50px 40px;
  
  @media (max-width: ${breakpoints.md}) {
    padding: 32px 16px;
  }
`;

const Body = () => {
  const [state] = useContext(StoreContext);
  const {
    predictions: {
      id,
    }, user: { userName } = {},
  } = state;

  return (
    <Wrap>
      {/* Help with text for user about bets */}
      { id && <TopAlert text={dictionary.predictionHelpText} /> }

      {/* Matches table by active league */}
      {id && <Matches />}
      {
        userName && (
          <>
            {/* Game History component */}
            {!id && <GameHistory />}
          </>
        )
      }
    </Wrap>
  );
};

export default Body;
