import React from 'react';

import styled from '@emotion/styled';
import { breakpoints, flex, color } from '../../../../../utils/style';
import { getMonthsArr } from '../../../../../utils/system';

const Wrap = styled('div')`
  width: 55px;
  height: 55px;
  user-select: none;
  
  ${flex};
  flex-direction: column;
  justify-content: space-around;
  
  & .time {
    & span {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: ${color.white};
    }
  }
  
  & .date {
    opacity: 0.6;
    
    ${flex};
    justify-content: space-between;
  
    & span {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.17;
      letter-spacing: normal;
      color: ${color.white};
      text-transform: uppercase;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    width: 40px;
    height: 40px;
  
    & .time {
      font-size: 14px;
    }
  }
`;

const DateTime = ({ date, time }) => {
  const matchDate = new Date(date);

  return (
    <Wrap>
      <div className="time">
        <span>{time}</span>
      </div>
      <div className="date">
        <span>{matchDate.getDate()}</span>&nbsp;
        <span>{getMonthsArr(true)[matchDate.getMonth()]}</span>
      </div>
    </Wrap>
  );
};

export default DateTime;
