import React, { useState, useEffect, useContext } from 'react';
import { navigate } from '@reach/router';

import styled from '@emotion/styled';
import { breakpoints, flex, hideScroll, resetButton, color } from '../../../../utils/style';
import dictionary from '../../../../utils/dictionary';
import tabsConfig from './tabsConfig';

import TableDate from './TableDate';
import TableTeams from './TableTeams';
import TableTeamsMobile from './TableTeamsMobile';
import { predictionUserBets } from '../../../../utils/api/prediction';
import { StoreContext } from '../../../../store';
import { setProfileBets, setProfileMenu } from '../../../../store/actions';
import WinMode from './WinMode';
import Points from './Points';
import WithLoader from '../../../shared/WithLoader';
import WithError from '../../../shared/WithError';
import { isMobile } from '../../../../utils/system';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  user-select: none;

  & * {
    user-select: none;
  }

  & .title {
    margin-bottom: 30px;

    & > span {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${color.white};
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100% !important;
    //background-color: ${color.bunting};
  }
`;

const Headers = styled.div`
  width: 100%;
  height: 30px;
  ${flex};
  justify-content: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  & .header {
    height: 100%;
    text-align: center;

    & > span {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      opacity: 0.6;
    }

    &.fluid {
      width: 100%;
    }

    &.text-left {
      text-align: left;
      width: 50px;
    }

    &.score {
      display: none;
    }

    &.prediction {
      width: 160px;
    }

    &.score {
      width: 250px;
    }

    &.points {
      width: 80px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    & .header {
      width: max-content !important;

      & > span {
        font-size: 12px;
        opacity: 0.6;
      }

      &.score {
        width: 115px;
      }

      &.text-left {
        width: 55px;
      }

      &.score {
        display: block;
        margin-left: auto;
      }

      &.prediction {
        margin-left: 6px;
      }

      &.points {
        margin-left: 6px;
      }
    }
  }
`;

const Rows = styled.div`
  width: 100%;
  height: 82%;
  overflow: hidden;
  overflow-y: auto;

  ${hideScroll};

  & .row {
    width: 100%;
    height: 55px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    ${flex};
    justify-content: flex-start;

    &:last-child {
      border-bottom: none;
    }

    & .points {
      margin-left: 35px;
    }
  }

  & > h2 {
    text-align: center;
  }

  @media (max-width: ${breakpoints.md}) {
    max-height: calc(70px * 5);
    overflow-y: scroll;
    ${hideScroll};

    & .row {
      height: 70px;

      & .points {
        width: max-content;
        margin-left: 6px;
      }
    }
  }
`;

const Info = styled('div')`
  width: 100%;
  height: max-content;
  margin-top: 20px;

  ${flex};
  justify-content: space-between;

  & .balance {
    .balance {
      width: 100%;
    }
    & > span {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 15px;
      color: ${color.white};

      & > button {
        ${resetButton};
        color: ${color.lightningYellow};

        &:hover {
          color: #62b9ff;
        }

        &:active {
          opacity: 0.8;
        }
      }
    }
  }

  & .total-points {
    & > span {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;

      &:last-child {
        color: ${color.supernova};
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    & .balance {
      width: 90px;
    }

    & .total-points {
      ${flex};
      flex-direction: column;
    }
  }
`;

const BetsTable = ({ withTitle = false }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [mobile, setIsMobile] = useState(false);

  const [state, dispatch] = useContext(StoreContext);
  const {
    profile: { bets, activeSubTab },
    predictions: { totalPoints } = {},
  } = state;

  useEffect(() => {
    setIsMobile(isMobile());
  });

  useEffect(() => {
    if (bets) {
      setIsLoading(false);
      setError('');
      return () => {};
    }

    predictionUserBets()
      .then((json) => {
        if (!json) {
          setError(dictionary.formBagRequest);
          setIsLoading(false);
          return false;
        }

        const { status, details, result = [] } = json;
        if (!status) {
          setError(details);
          setIsLoading(false);
          return false;
        }

        setProfileBets(dispatch, result);
        setIsLoading(false);
        setError('');
      })
      .catch((err) => {
        setIsLoading(false);
        setError(dictionary.formBagRequest);
        // console.log('Bets request error: ', err);
      });
  }, []);

  // Handlers
  const toBalance = () => {
    setProfileMenu(dispatch, 'winning_bonuses');
    navigate('/profile/');
  };

  return (
    <Wrap className="history-table">
      {withTitle && (
        <div className="title">
          <span>{dictionary.gameHistory}</span>
        </div>
      )}
      <Headers>
        <div className="header text-left">
          <span>{dictionary.date}</span>
        </div>
        <div className="header fluid" />
        <div className="header score">
          <span>{dictionary.score}</span>
        </div>
        <div className="header prediction">
          <span>{dictionary.prediction}</span>
        </div>
        <div className="header points">
          <span>{dictionary.points}</span>
        </div>
      </Headers>
      <WithLoader isLoading={isLoading}>
        <WithError error={error}>
          <Rows>
            {(!bets || bets.length === 0) && <h2>{dictionary.youStillNotHaveBets}</h2>}
            {bets &&
              bets?.map((match) => {
                const { date, time, away, home, away_score, home_score, points, played, predict_num } = match;

                if (Boolean(played) && tabsConfig[activeSubTab] === 'OPEN_BETS') return null;
                if (!played && tabsConfig[activeSubTab] === 'ALREADY_PLAYED_BETS') return null;

                return (
                  <div className="row">
                    <TableDate matchDate={date} time={time} />

                    {mobile ? (
                      <TableTeamsMobile
                        completed={Boolean(played)}
                        home={home}
                        away={away}
                        homePredict={home_score}
                        awayPredict={away_score}
                      />
                    ) : (
                      <TableTeams
                        completed={Boolean(played)}
                        home={home}
                        away={away}
                        homePredict={home_score}
                        awayPredict={away_score}
                      />
                    )}

                    <WinMode predictNum={predict_num} />
                    <Points points={points} />
                  </div>
                );
              })}
          </Rows>
          <Info>
            <div className="balance">
              <span>
                {dictionary.checkYou}&nbsp;
                <button type="button" onClick={toBalance}>
                  {dictionary.balanceHere}
                </button>
              </span>
            </div>
            <div className="total-points">
              <span>{dictionary.total}</span>
              <span>
                &nbsp;{totalPoints}&nbsp;{dictionary.points}
              </span>
            </div>
          </Info>
        </WithError>
      </WithLoader>
    </Wrap>
  );
};

export default BetsTable;
