import React from 'react';

import styled from '@emotion/styled';
import dictionary from '../../../../../utils/dictionary';
import { breakpoints, color } from '../../../../../utils/style';

import List from './List';

const Wrap = styled('div')`
  height: 100%;
  user-select: none;
    
  & .title {
    margin-bottom: 30px;
  
    & > span {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${color.white};
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    width: calc(288px - 32px)!important;
    margin-top: 50px;
  }
`;

const Leaderboard = () => (
  <Wrap className="leaderboard">
    <div className="title"><span>{dictionary.leaderboard}</span></div>
    <List />
  </Wrap>
);

export default Leaderboard;
