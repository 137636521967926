import React from 'react';
import styled from '@emotion/styled';
import { flex } from '../../utils/style';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  
  ${flex};
  
  & h2 {
    margin: 0;
    padding: 0;
  }
`;

const FullError = ({ error }) => (
  <Wrap>
    <h2>{error}</h2>
  </Wrap>
);

export default FullError;
