import React, { useContext, useCallback, useState, useEffect } from 'react';

import styled from '@emotion/styled';
import dictionary from '../../../../utils/dictionary';
import { border, breakpoints, flex, hideScroll, resetButton, color } from '../../../../utils/style';
import { timeDiff } from '../../../../utils/system';

import { StoreContext } from '../../../../store';
import {
  toggleLeague,
  setCurrPredictionsLeagueId,
  predictionSetAccepted,
  setProfileBets, loadPredictionsRounds,
} from '../../../../store/actions';
import Match from './Match';
import PredictionTimer from '../PredictionTimer';
import PredictionAccepted from '../PredictionAccepted';
import { predictionMakeBet, predictionUserBets } from '../../../../utils/api/prediction';
import FullError from '../../FullError';
import WithError from '../../WithError';
import { useModalOpen } from '../../Modal';

const Wrap = styled('div')`
  margin-top: 40px;
  position: relative;
  
  ${flex};
  justify-content: space-between;
  flex-wrap: wrap;

  & > h2 {
    text-align: center;
  }
  
  & .bottom-panel {
    width: 100%;
    margin-top: 40px;
    
    ${flex};
    justify-content: space-between;
    
    & .game-history {
      ${resetButton};
      user-select: none;
      
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${color.blue};
      
      &:hover {
        color: #57b4f6;
      }
      
      &:active {
        opacity: 0.9;
      }
      
      &.hide {
        visibility: hidden;
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    justify-content: space-around;
  }
`;

const SubmitButton = styled('button')`
  ${resetButton};
  
  width: 114px;
  height: 44px;
  border-radius: ${border.borderRadius4};
  background-color: ${color.darkPink};
  user-select: none;
  
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${color.catskillWhite};
  
  &:hover {
    opacity: 0.8;
  }
  
  &:active {
    opacity: 1;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
`;

const MobileScrollContainer = styled('div')`
  ${flex};
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    max-height: calc(62px * 5.5);
    overflow-y: scroll;
    ${hideScroll};
    justify-content: space-around;
  }
`;

const Matches = () => {
  const [state, dispatch] = useContext(StoreContext);
  const {
    predictions: {
      id,
      isRoundsLoaded,
      predictionData,
      matchBets,
      currentLeague: { last_bet_time } = {},
    } = {},
    application: { isMobile } = {},
    profile: { betsFormatted } = {},
    user: { userName } = {},
  } = state;

  const [error, setError] = useState('');
  useEffect(() => {
    const timeout = setTimeout(() => setError(error), 5000);
    return () => { clearTimeout(timeout); };
  }, [error]);

  if (!isRoundsLoaded || !id) return null;

  // Data handlers
  const fromObjToArr = (obj) => (!obj ? [] : Object.keys(obj)?.map((key) => obj[key]));
  const roundsFiltered = predictionData.filter(({ id: leagueId }) => (leagueId === id));
  const activeRound = roundsFiltered.length > 0 ? roundsFiltered[0] : {};
  const rounds = fromObjToArr(activeRound.rounds);
  const matchesCount = useCallback(() => Object.keys(matchBets).length, [matchBets]);

  // Components handlers
  const loginOpenHandler = useModalOpen('login-popup-window');
  const toGameHistory = useCallback(() => {
    toggleLeague(dispatch);
    setCurrPredictionsLeagueId(dispatch, '');
    window.location.href = '#home-predictions';
  }, []);
  const sendBets = (e) => {
    if (!userName) {
      loginOpenHandler(e);
      return false;
    }
    if (timeDiff(last_bet_time)) return false;

    predictionMakeBet(id, matchBets)
      .then((json) => {
        if (!json) {
          setError(dictionary.formBagRequest);
          return () => { };
        }

        const { success, message } = json;
        if (!success) {
          setError(message);
          return () => { };
        }

        predictionSetAccepted(dispatch, true);

        predictionUserBets()
          .then((json) => {
            if (!json) return false;

            const { status, result = [] } = json;
            if (!status) return false;

            setProfileBets(dispatch, result);
          })
          .catch((err) => {
            // console.log('Bets request error: ', err);
          });

        loadPredictionsRounds(dispatch);
      })
      .catch((err) => {
        // console.log('make prediction request: ', err);
      });
  };

  if (error) return <FullError error={error} />;

  return (
    <Wrap>
      <WithError error={error}>
        <PredictionAccepted />
        {rounds.length === 0 && <h2>{dictionary.noMatchesForBet}</h2>}
        {
          rounds && (
            <MobileScrollContainer>
              {
                rounds?.map(({ round_id, matches }) => {
                  if (!matches || matches.length === 0) return null;
                  return fromObjToArr(matches)?.map((match) => (
                    <Match
                      key={match.match_id}
                      roundId={round_id}
                      match={match}
                      selected={betsFormatted[match.match_id] ? match.match_id : false}
                    />
                  ));
                })
              }
            </MobileScrollContainer>
          )
        }
        <div className="bottom-panel">
          {activeRound && (
            <SubmitButton
              onClick={sendBets}
              disabled={!(matchesCount() > 0)}
            >{dictionary.makeABet}
            </SubmitButton>
          )}
          {!isMobile && <PredictionTimer />}
          <button
            type="button"
            className={`game-history ${!userName ? 'hide' : ''}`}
            onClick={toGameHistory}
          >{dictionary.gameHistory}
          </button>
        </div>
        {isMobile && <PredictionTimer />}
      </WithError>
    </Wrap>
  );
};

export default Matches;
