import React from 'react';

import styled from '@emotion/styled';
import { breakpoints, flex, textEllipsis, color } from '../../../../../utils/style';

const Wrap = styled('div')`
  width: 145px;
  height: 100%;
  margin-left: 8px;
  user-select: none;
  
  ${flex};
  flex-direction: column;
  
  & .team {
    width: 100%;
    height: max-content;
    
    ${flex};
    justify-content: flex-start;
    
    & > img {
      width: 20px;
    }
    
    & .name {
      ${textEllipsis};
      & > span {
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: ${color.white};
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    width: 92px;
    
    & .name {
      font-size: 14px;
    }
  }
`;

const Teams = ({ away, home }) => {
  const { logo: logoAway, name: nameAway } = away;
  const { logo: logoHome, name: nameHome } = home;

  return (
    <Wrap>
      <div className="team">
        <img src={logoHome} alt="home-logo" />
        <div className="name">
          <span>{nameHome}</span>
        </div>
      </div>
      <div className="team">
        <img src={logoAway} alt="away-logo" />
        <div className="name">
          <span>{nameAway}</span>
        </div>
      </div>
    </Wrap>
  );
};

export default Teams;
