import React, { useContext } from 'react';
import { navigate } from '@reach/router';

import styled from '@emotion/styled';
import dictionary from '../../../utils/dictionary';
import { border, breakpoints, flex, resetButton, color } from '../../../utils/style';

import { StoreContext } from '../../../store';
import { predictionSetAccepted, setProfileMenu } from '../../../store/actions';

const Wrap = styled('div')`
  width: 424px;
  height: 239px;
  border-radius: ${border.borderRadius4};
  background-color: ${color.cloudBurst};
  z-index: 10;
  padding: 0 105px;
  user-select: none;
  
  position: absolute;
  left: calc(50% - 212px);
  
  ${flex};
  flex-direction: column;
  
  & > h3 {
    margin: 0 0 10px 0;
    padding: 0;
    
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.blue};
  }
  
  & > p {
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: ${color.alabaster};
    
    & > button {
      ${resetButton};
      color: ${color.blue};
    }
  }
  
  & > button {
    ${resetButton};
    
    width: 90px;
    height: 44px;
    margin-top: 22px;
    background-color: ${color.darkPink};
    border-radius: ${border.borderRadius4};
    
    font-family: LucidaGrande;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${color.catskillWhite};
    
    &:hover {
      background-color: #dc003f;
    }
    
    &:active {
      opacity: 0.8;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    width: 288px;
    padding: 0 10px;
    
    left: -16px;
    top: unset;
    bottom: 170px;
  }
`;

const BG = styled('div')`
  width: 100%;
  height: 100%;
  z-index: 9;
  
  position: absolute;
  top: 0;
  left: 0;
`;

const PredictionAccepted = () => {
  const [state, dispatch] = useContext(StoreContext);
  const {
    predictions: {
      accepted,
    } = {},
  } = state;

  if (!accepted) return null;

  // Handlers
  const toBalance = () => {
    setProfileMenu(dispatch, 'winning_bonuses');
    navigate('/profile/');
  };
  const onClose = () => predictionSetAccepted(dispatch, false);

  return (
    <>
      <Wrap>
        <h3>{dictionary.betAccepted}</h3>
        <p>{dictionary.yourPredictionIsAccepted}<button
          type="button"
          className="game-history"
          onClick={toBalance}
        >{dictionary.betHistoryHere}
        </button>
        </p>
        <button
          type="button"
          className="close"
          onClick={onClose}
        >{dictionary.ok}
        </button>
      </Wrap>
      <BG />
    </>
  );
};

export default PredictionAccepted;
