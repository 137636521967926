import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, flex, color } from '../../../../utils/style';

const Wrap = styled.div`
  width: max-content;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  ${flex};
  
  .team-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    
    & span.none {
         color: ${color.transparentWhite4};
      }
    }
  }
  
  .team {
    //width: 150px;
    height: 100%;
    
    ${flex};
    justify-content: flex-start;
    
    & > img {
      width: 20px;
      margin-right: 17px;
    }
    
    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 15px;
      font-weight: 500;
      color: ${color.white};
    }
  }
  
  .divider {
    margin: 0 50px;
    
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${color.blue} 
  }
  
  &.completed {
    .team {
      & > span {
        opacity: 1;
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    position: relative;
    flex-direction: column;
    align-content: space-between;
    padding: 12px 0;
    margin-left: 0;
    
    .team {
      //width: 100px;
      padding-left: 0;
    
      & > img {
        width: 20px;
        margin-right: 6px;
      }
    }
  
    .divider {
      display: none;
    }
  }
`;

const teamBox = (name, logo) => (
  <div className="team">
    <img alt={name} src={logo} />
    <span>{name}</span>
  </div>
);

const TableTeams = ({ home = {}, away = {}, completed = true, homePredict, awayPredict }) => {
  const { name: homeName, logo: homeLogo } = home;
  const { name: awayName, logo: awayLogo } = away;

  return (
    <>
      <Wrap className={completed ? 'completed' : ''}>
        <div className="team-row">
          {teamBox(homeName, homeLogo)}
          <span className={completed ? '' : 'none'}>{completed ? homePredict : '?'}</span>
        </div>
        <div className="team-row">
          {teamBox(awayName, awayLogo)}
          <span className={completed ? '' : 'none'}>{completed ? awayPredict : '?'}</span>
        </div>
      </Wrap>
    </>
  );
};

export default TableTeams;
