import React, { useState, useEffect, useContext } from 'react';

import styled from '@emotion/styled';
import dictionary from '../../../utils/dictionary';
import { addZero, timeDiff } from '../../../utils/system';
import { breakpoints, color } from '../../../utils/style';

import { StoreContext } from '../../../store';

const Wrap = styled('div')`
  user-select: none;

  & > span {
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: ${color.white};
  }
  
  & > strong {
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: center;
    color: ${color.supernova};
    
    margin-left: 10px;
  }
  
  @media (max-width: ${breakpoints.md}) {
    margin-top: 20px;
    text-align: center;
  }
`;

const PredictionTimer = () => {
  const [state] = useContext(StoreContext);
  const { predictions: { currentLeague: { last_bet_time } } = {} } = state;

  const parsedTime = timeDiff(last_bet_time);

  if (!parsedTime) return null;
  if (parsedTime.hours < 0 && parsedTime.minutes < 0) return null;

  const [hours, setHours] = useState(parsedTime.hours);
  const [minutes, setMinutes] = useState(parsedTime.minutes);
  const [seconds, setSeconds] = useState(parsedTime.seconds);

  useEffect(() => {
    if (hours === 0 && minutes === 0 && seconds === 0) return () => {};

    const interval = setInterval(() => {
      if (seconds === 0) {
        setSeconds(59);

        if (minutes === 0) {
          setMinutes(59);
          setHours((prevState) => (prevState - 1));
        } else setMinutes((prevState) => (prevState - 1));
      } else setSeconds((prevState) => (prevState - 1));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  if (hours === 0 && minutes === 0 && seconds === 0) return null;

  return (
    <Wrap>
      <span>{dictionary.timerText}</span>
      <strong>{addZero(hours)}:{addZero(minutes)}:{addZero(seconds)}</strong>
    </Wrap>
  );
};

export default PredictionTimer;
