import React from 'react';

import Loader from './Loader';

const WithLoader = ({ isLoading, children }) => (
  <>
    {isLoading && <Loader />}
    {!isLoading && children}
  </>
);

export default WithLoader;
