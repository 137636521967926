import React from 'react';

import styled from '@emotion/styled';
import { breakpoints, flex } from '../../../../utils/style';

import Leaderboard from './Leaderboard';
import BetsTable from '../../../Profile/InfoPanel/Bets/BetsTable';

const Wrap = styled('div')`
  width: 100%;
  height: 500px;
  
  ${flex};
  justify-content: space-between;

  & .history-table {
    width: 724px;
  }
  
  & .leaderboard {
    width: 288px;
  }
  
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    height: max-content;
  }
`;

const GameHistory = () => (
  <Wrap>
    <BetsTable withTitle />
    <Leaderboard />
  </Wrap>
);

export default GameHistory;
