import React from 'react';

import styled from '@emotion/styled';
import dictionary from '../../../utils/dictionary';
import IcomoonIcon from '../IcomoonIcon';
import { breakpoints, flex, color } from '../../../utils/style';

import Tabs from './Tabs';
import Body from './Body';

const Wrapper = styled('div')`
  & .title {
    ${flex};
    justify-content: flex-start;
  
    & > svg {
      margin-right: 12px;
    }
  
    & > span {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${color.blue};
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    & .title {
      & > span {
        font-size: 22px;
      }
    }
  }
`;

const Prediction = () => (
  <Wrapper id="home-predictions">
    <div className="title">
      <IcomoonIcon icon="prediction-icon" color="" className="" size={37} />
      <span>{dictionary.predictionHomeTitle}</span>
    </div>

    {/* Tabs of Predictions */}
    <Tabs />

    {/* Table of Predictions */}
    <Body />

  </Wrapper>
);

export default Prediction;
